import { Grid } from '@/features/design/grid/Grid'
import { Row } from '@/features/design/grid/Row'
import { Column } from '@/features/design/grid/Column'
import {
  Container,
  ContentContainer,
  ListLayout,
  RowCard,
} from '@amstudio/react-native-components'
import { Platform, ScrollView, useWindowDimensions } from 'react-native'
import { useUserContext } from '@/features/users/hooks/useUserContext'
import { useTranslation } from 'react-i18next'
import { BoardMembersStackContainer } from '@/features/property/containers/BoardMembersStackContainer'
import { NeighbourhoodMap } from '@/features/neighbourhood/components/NeighbourhoodMap'
import { useMyNeighbourhoodProperties } from '@/features/neighbourhood/hooks/useMyNeighbourhoodProperties'
import { NeighbourhoodMapMarker } from '@/features/neighbourhood/components/types'
import { PropertyFilesContainer } from '@/features/property-files/containers/PropertyFilesContainer'
import { useMyPropertyEnergyDeclaration } from '@/features/property-energy/hooks/useMyPropertyEnergyDeclaration'

export default function PropertyInformationScreen() {
  const { property, neighborhood } = useUserContext()
  const { data: neighbourhoodProperties } = useMyNeighbourhoodProperties()
  const dimensions = useWindowDimensions()
  const { t } = useTranslation('locallife')

  const propertyExtraInfo = neighbourhoodProperties?.find(
    (p) => p.id === property.id
  )
  const { data: energyDeclaration, isLoading: isLoadingEnergyDeclaration } =
    useMyPropertyEnergyDeclaration()

  const mapMarkers: NeighbourhoodMapMarker[] = propertyExtraInfo?.coordinates
    ? [
        {
          id: property.id,
          name: property.name,
          coordinates: propertyExtraInfo.coordinates,
          energyClass: propertyExtraInfo.energyClass,
        },
      ]
    : []

  const mainBuildingAddress = energyDeclaration?.buildings
    .find((b) => b.addresses.find((a) => a.isMainAddress))
    ?.addresses.find((a) => a.isMainAddress)

  return (
    <ScrollView showsVerticalScrollIndicator={Platform.OS === 'web'}>
      <ContentContainer
        noSafeArea
        header={{
          title: t('property.information.title'),
          surtitle: property.name,
          subtitle:
            'Find documents, board members and other information about your property',
          onBackPress: false,
        }}
      >
        <Grid rowGap={32} columnGap={32}>
          <Row>
            <Column flex={1.5}>
              <Container
                style={{
                  rowGap: 16,
                }}
                flex={false}
              >
                <NeighbourhoodMap
                  markers={mapMarkers}
                  height={dimensions.height * 0.25}
                  maxZoom={17}
                />
                <ListLayout
                  rowGap={16}
                  items={[
                    {
                      key: 'property_name',
                      title: t('property.information.propertyName', 'Property'),
                      description: property.name,
                    },
                    {
                      key: 'neighborhood',
                      title: t(
                        'property.information.neighborhood',
                        'Neighborhood'
                      ),
                      description: neighborhood.name,
                    },
                    {
                      key: 'address',
                      title: t('property.information.address', 'Address'),
                      description: isLoadingEnergyDeclaration
                        ? '...'
                        : mainBuildingAddress?.fullStreetAddress +
                          ', ' +
                          energyDeclaration?.city,
                    },
                    {
                      key: 'apartments',
                      title: t(
                        'property.information.apartments',
                        'Number of Apartments'
                      ),
                      description: !isLoadingEnergyDeclaration
                        ? (
                            energyDeclaration?.numberOfApartments ||
                            'Not specified'
                          ).toString()
                        : '...',
                    },
                    {
                      key: 'construction_year',
                      title: t(
                        'property.information.constructionYear',
                        'Construction Year'
                      ),
                      description: !isLoadingEnergyDeclaration
                        ? (
                            energyDeclaration?.constructionYear ||
                            'Not specified'
                          ).toString()
                        : '...',
                    },
                  ]}
                  renderItem={(item) => (
                    <RowCard
                      title={item.title}
                      description={item.description}
                    />
                  )}
                />
              </Container>
              <Container>
                <PropertyFilesContainer viewType="grid" />
              </Container>
            </Column>
            <Column>
              <Container flex={false}>
                <BoardMembersStackContainer />
              </Container>
            </Column>
          </Row>
        </Grid>
      </ContentContainer>
    </ScrollView>
  )
}
