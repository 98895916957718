import { PostPublication } from '@/data/objects/post-publication-objects'
import { ListLayout, Text } from '@amstudio/react-native-components'
import { PostCommentCard } from './posts/PostCommentCard'
import { NewMessageInput } from '@/features/conversations/components/NewMessageInput'
import { useState } from 'react'
import { usePostComments, useCommentActions } from '@/features/publications'
import { PublicationCard } from './PublicationCard'
import { useTheme } from '@amstudio/react-native-config'
import { View } from 'react-native'

export type ContentType = 'post' | 'notice'

interface Props {
  content: PostPublication
  contentType: ContentType
  audience?: 'property' | 'neighborhood' | 'neighborhood_property_boards'
}

export function PublicationContentDetails({ content, contentType }: Props) {
  const { colors } = useTheme()

  const {
    comments,
    isFetchingNextPage,
    loadMoreComments,
    hasMoreComments,
    isLoading: isLoadingComments,
  } = usePostComments(content.postId, content.comments ?? [])

  const [comment, setComment] = useState('')
  const { createPostComment, isPostingComment } = useCommentActions(
    content.postId
  )

  const onPressSend = async () => {
    await createPostComment(
      {
        conversationId: content.postId,
        content: comment,
      },
      () => {
        setComment('')
      }
    )
  }

  const sectionTitle =
    contentType === 'notice'
      ? 'Comments'
      : content.comments.length > 0
        ? 'Replies'
        : ''

  const emptyStateMessage =
    contentType === 'notice'
      ? 'There are no comments on this post yet'
      : 'No replies yet...start the conversation!'

  return (
    <View
      style={{
        flex: 1,
        rowGap: 12,
      }}
    >
      <ListLayout
        items={comments}
        renderItem={(comment) => (
          <PostCommentCard postId={content.postId} comment={comment} />
        )}
        pagination={{
          isLoading: isFetchingNextPage,
          loadMore: loadMoreComments,
          hasMoreToLoad: hasMoreComments,
        }}
        header={
          <>
            <PublicationCard
              backgroundColor={(colors) => colors.background.secondary}
              publication={content}
              style={{
                backgroundColor: colors.background.secondary,
                paddingVertical: 25,
              }}
              onPress={() => {}}
            />
            <Text
              variant={'title4'}
              style={{
                marginTop: 24,
                marginBottom: sectionTitle ? 0 : -24,
              }}
            >
              {sectionTitle}
            </Text>
          </>
        }
        footer={
          !isLoadingComments && comments.length === 0 ? (
            <Text
              variant={'captionTitle'}
              color={'secondary'}
              style={{
                textAlign: 'center',
                marginBottom: 50,
              }}
            >
              {emptyStateMessage}
            </Text>
          ) : undefined
        }
        rowGap={12}
      />
      <NewMessageInput
        message={comment}
        setMessage={setComment}
        onPressSend={onPressSend}
        isSending={isPostingComment}
      />
    </View>
  )
}
